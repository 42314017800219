import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  libraryUsers: [],
  catalogues: [],
  acquisitions: [],
  buildings: [],
  accessionData: null,
  accession: null,
  recentCirculations: [],
  recentTransactions: [],
  redirect: ""
};

const appReducer = (state = initState, action) => {  
  let temp, index = -1;
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:        
    case appConstants.GET_ACCESSIONS_REQUEST:
    case appConstants.GET_ACCESSION_REQUEST:
    case appConstants.MANAGE_ACCESSION_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:
    case appConstants.UPDATE_AVATAR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,  
        libraryUsers: action.payload?.libraryUsers,
        catalogues: action.payload?.catalogues,
        acquisitions: action.payload?.acquisitions,
        buildings: action.payload?.buildings,   
        recentCirculations: action.payload?.recentCirculations,
        recentTransactions: action.payload?.recentTransactions,   
        loading: false,
      };    

    case appConstants.GET_ACCESSIONS_SUCCESS:
      return {
        ...state,
        accessionData: action.payload,
        loading: false,
      };

    case appConstants.GET_ACCESSION_SUCCESS:
      return {
        ...state,
        accession: action.payload,
        loading: false,
      };

    case appConstants.MANAGE_ACCESSION_SUCCESS:
      return {
        ...state,
        accession: action.payload?.data,
        message: action.payload?.message,
        loading: false,
      };

    case appConstants.UPDATE_CATALOGUE:
      temp = state.catalogues;      
      index = -1;
      index = temp.findIndex(item => item.id === action.payload.id);
      if(index !== -1){
        temp[index] = action.payload;
      }else{
        temp.push(action.payload);
      }
      return {
        ...state,
        catalogues: temp
      }

    case appConstants.UPDATE_ACQUISITION:
      temp = state.acquisitions;
      index = -1;
      index = temp.findIndex(item => item.id === action.payload?.id);
      if(index !== -1){
        temp[index] = action.payload;
      }else{
        temp.push(action.payload);
      }      
      return {
        ...state,
        acquisitions: temp
      }

    case appConstants.UPDATE_BUILDING:
      temp = state.buildings;
      index = -1;
      index = temp.findIndex(item => item.id === action.payload?.id);
      if(index !== -1){
        temp[index] = action.payload;
      }else{
        temp.push(action.payload);
      }      
      return {
        ...state,
        buildings: temp
      }

    case appConstants.UPDATE_PASSWORD_SUCCESS:
    case appConstants.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        message: action.payload?.message,
        loading: false,
      };
        
    case appConstants.GET_INITIAL_DATA_FAILURE:        
    case appConstants.GET_ACCESSIONS_FAILURE:
    case appConstants.GET_ACCESSION_FAILURE:
    case appConstants.MANAGE_ACCESSION_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:
    case appConstants.UPDATE_AVATAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;