import { DashboardOutlined } from "@ant-design/icons";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

const icons = {
  AccountBalanceWalletOutlinedIcon,
  AddCardOutlinedIcon,
  AssessmentOutlinedIcon,
  DashboardOutlined,
  LibraryBooksOutlinedIcon  
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "accession",
      title: "Accession",
      type: "item",
      url: "/accession",
      icon: icons.LibraryBooksOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },
    {
      id: "circulation",
      title: "Circulation",
      type: "item",
      url: "/circulation",
      icon: icons.AddCardOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    }, 
    {
      id: "faculty-circulation",
      title: "Faculty Circulation",
      type: "item",
      url: "/faculty-circulation",
      icon: icons.AddCardOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },  
    {
      id: "reports",
      title: "Reports",
      type: "item",
      url: "/reports",
      icon: icons.AssessmentOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    },    
    {
      id: "transactions",
      title: "Transactions",
      type: "item",
      url: "/transactions",
      icon: icons.AccountBalanceWalletOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    },    
  ]
};

export default dashboard;