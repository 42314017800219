import { circulationConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageCirculation = (id, form, type) => {
  return async dispatch => {
    try{
      dispatch({ type: circulationConstants.MANAGE_CIRCULATION_REQUEST });
      let _data;
      if(id){        
        _data = await Axios.put(`/library/circulation/${type}/return/${id}`, form);
      }else{
        _data = await Axios.post(`/library/circulation/${type}/issue`, form);
      }
      const { data } = _data;
      if(id){
        dispatch({ 
          type: circulationConstants.MANAGE_CIRCULATION_RETURN_SUCCESS,
          payload: {             
            message: data?.message
          }
        });
        if(type === "student"){
          dispatch(getCirculation(id, "student"));
        }else if(type === "faculty"){
          dispatch(getCirculation(id, "faculty"));
        }
      }else{      
        if(type === "student"){
          dispatch({ 
            type: circulationConstants.MANAGE_STUDENT_CIRCULATION_ISSUE_SUCCESS,
            payload: { 
              data: data?.data,
              message: data?.message
            }
          });
        }else if(type === "faculty"){
          dispatch({ 
            type: circulationConstants.MANAGE_FACULTY_CIRCULATION_ISSUE_SUCCESS,
            payload: { 
              data: data?.data,
              message: data?.message
            }
          });
        }
      }
    }catch(error){
      dispatch({ 
        type: circulationConstants.MANAGE_CIRCULATION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCirculations = (filter, page, limit, type) => {
  return async dispatch => {
    try{
      dispatch({ type: circulationConstants.GET_CIRCULATIONS_REQUEST });      
      let URL = `/library/circulation/${type}?page=${page}&limit=${limit}&libraryId=${filter?.libraryId}`;
      if(filter?.query) URL += `&query=${filter?.query}`;
      if(filter?.accessionNumber) URL += `&accessionNumber=${filter?.accessionNumber}`;
      if(filter?.status) URL += `&status=${filter?.status}`;
      if(filter?.startDate && filter?.endDate) URL += `&startDate=${filter?.startDate}&endDate=${filter?.endDate}`;
      const { data } = await Axios.get(URL);      
      if(type === "student"){
        dispatch({ 
          type: circulationConstants.GET_STUDENT_CIRCULATIONS_SUCCESS,
          payload: data?.data
        });
      }else if(type === "faculty"){
        dispatch({ 
          type: circulationConstants.GET_FACULTY_CIRCULATIONS_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: circulationConstants.GET_CIRCULATIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCirculation = (id, type) => {
  return async dispatch => {
    try{
      dispatch({ type: circulationConstants.GET_CIRCULATION_REQUEST });      
      const { data } = await Axios.get(`/library/circulation/${type}/${id}`);      
      if(type === "student"){
        dispatch({ 
          type: circulationConstants.GET_STUDENT_CIRCULATION_SUCCESS,
          payload: data?.data
        });
      }else if(type === "faculty"){
        dispatch({ 
          type: circulationConstants.GET_FACULTY_CIRCULATION_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: circulationConstants.GET_CIRCULATION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const notifyCirculation = (id, type) => {
  return async dispatch => {
    try{
      dispatch({ type: circulationConstants.NOTIFY_CIRCULATION_REQUEST });
      const { data } = await Axios.get(`/library/notify/${type}/${id}`);
      dispatch({ 
        type: circulationConstants.NOTIFY_CIRCULATION_SUCCESS,
        payload: {             
          message: data?.message
        }
      });      
    }catch(error){
      dispatch({ 
        type: circulationConstants.NOTIFY_CIRCULATION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const payFine = (id, form, type) => {
  return async dispatch => {
    try{
      dispatch({ type: circulationConstants.PAY_FINE_REQUEST });      
      const { data } = await Axios.post(`/library/circulation/${type}/fine/${id}`, form);;      
      dispatch({ 
        type: circulationConstants.PAY_FINE_SUCCESS,
        payload: {             
          message: data?.message
        }
      });
      if(type === "student"){
        dispatch(getCirculation(id, "student"));
      }else if(type === "faculty"){
        dispatch(getCirculation(id, "faculty"));
      }      
    }catch(error){
      dispatch({ 
        type: circulationConstants.PAY_FINE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}