import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Autocomplete, CircularProgress, FormHelperText, Grid, Stack, TextField } from "@mui/material";

const AppInputSelectAutocomplete = ({
  id,  
  label,
  onChange,
  apiOptions,
  noOptionsText,
  touched,
  errors,  
  multiple = false,
  value,
  setQuery=null,
  inputRef=null,
  autoFocus=false,  
}) => {

  const [ open, setOpen ] = useState(false);
  const [ options, setOptions ] = useState([]);
  const [ inputValue, setInputValue ] = useState("");
  const loading = open && options.length === 0; 

  const debouncedSetQuery = useCallback(_.debounce((query) => {
    setQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 300), [setQuery]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (async () => {      
      if(active && inputValue !== "") {        
        const newOptions = apiOptions.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()));
        setOptions(newOptions);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, inputValue, apiOptions]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleInputChange = (event, newInputValue, reason) => {
    if (newInputValue.length > 2 && reason !== "reset" && setQuery) {
      debouncedSetQuery(newInputValue);
    }
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (value && options.length === 1) {
      onChange(null, options[0]);
      setOpen(false);
      if (inputRef) {
        inputRef.current.focus();
      }
    }
  }, [value, options, onChange, inputRef]);

  useEffect(() => {
    if (autoFocus && inputRef) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);
  
  return (
    <Grid item xs={12}>
      <Stack spacing={0.5}>        
        <Autocomplete
          multiple={multiple}
          id={id}
          sx={{ width: "100%" }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={(option) => option.label}
          options={options}
          value={value}
          loading={loading}          
          onInputChange={handleInputChange}
          noOptionsText={noOptionsText}
          onChange={(event, newValue) => {
            onChange(event, newValue);
            if (newValue && inputRef) {
              inputRef.current.focus();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}   
              inputRef={inputRef}           
              InputProps={{
                ...params.InputProps,
                style: {height: 41.5, paddingTop: 0, paddingBottom: 0},
                endAdornment: (
                  <React.Fragment>
                    {loading ? <Grid 
                      item
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",                        
                        mr: 1.5,
                      }}
                    >
                      <CircularProgress color="inherit" size={15} />
                    </Grid> : null}                    
                    {params.InputProps.endAdornment}                    
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        {touched && errors && (
          <FormHelperText 
            error 
            id={`standard-weight-helper-text-${id}`}
          >
            {errors}
          </FormHelperText>
        )}
      </Stack>
    </Grid>    
  )
}

export default AppInputSelectAutocomplete