import { appConstants } from "redux/constants";
import Axios from "utils/axios";
import { loadUser } from "./user.action";

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/library/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAccessions = (filter, page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_ACCESSIONS_REQUEST });      
      let URL = `/library/accession?page=${page}&limit=${limit}&libraryId=${filter?.libraryId}`;
      if(filter?.query) URL += `&query=${filter?.query}`;
      if(filter?.accessionNumber) URL += `&accessionNumber=${filter?.accessionNumber}`;
      if(filter?.libraryCatalogueId) URL += `&libraryCatalogueId=${filter?.libraryCatalogueId}`;
      if(filter?.libraryAcquisitionId) URL += `&libraryAcquisitionId=${filter?.libraryAcquisitionId}`;
      if(filter?.authors) URL += `&authors=${filter?.authors}`;
      if(filter?.status) URL += `&status=${filter?.status}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: appConstants.GET_ACCESSIONS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_ACCESSIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAccession = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_ACCESSION_REQUEST });      
      const { data } = await Axios.get(`/library/accession/${id}`);      
      dispatch({ 
        type: appConstants.GET_ACCESSION_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_ACCESSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageAccession = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.MANAGE_ACCESSION_REQUEST });
      let _data;
      if(id){
        _data = await Axios.put(`/library/accession/${id}`, form);              
      }else{
        _data = await Axios.post(`/library/accession`, form);              
      }
      const { data } = _data;
      dispatch({ 
        type: appConstants.MANAGE_ACCESSION_SUCCESS,
        payload: {
          data: data?.data,
          message: data?.message
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.MANAGE_ACCESSION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/library/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updateAvatar = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_AVATAR_REQUEST });
      const { data } = await Axios.put(`/auth/library/avatar`, form);            
      dispatch({ 
        type: appConstants.UPDATE_AVATAR_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
      dispatch(loadUser());
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_AVATAR_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}