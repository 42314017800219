import { appConstants, dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const manageCatalogue = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_CATALOGUE_REQUEST });            
      let _data;
      if(id){
        _data = await Axios.put(`/library/catalogue/${id}`, form);
      }else{
        _data = await Axios.post(`/library/catalogue`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_CATALOGUE_SUCCESS,
        payload: {
          message: data?.message,          
        }
      });
      dispatch({
        type: appConstants.UPDATE_CATALOGUE,
        payload: data?.data
      })
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_CATALOGUE_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageAcquisition = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_ACQUISITION_REQUEST });            
      let _data;
      if(id){
        _data = await Axios.put(`/library/acquisition/${id}`, form);
      }else{
        _data = await Axios.post(`/library/acquisition`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_ACQUISITION_SUCCESS,
        payload: {
          message: data?.message,          
        }
      });
      dispatch({
        type: appConstants.UPDATE_ACQUISITION,
        payload: data?.data
      })
    }catch(error){      
      dispatch({ 
        type: dataConstants.MANAGE_ACQUISITION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageBuilding = (id=null, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_BUILDING_REQUEST });            
      let _data;
      if(id){
        _data = await Axios.put(`/library/building/${id}`, form);
      }else{
        _data = await Axios.post(`/library/building`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_BUILDING_SUCCESS,
        payload: {
          message: data?.message,          
        }
      });
      dispatch({
        type: appConstants.UPDATE_BUILDING,
        payload: data?.data
      })
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_BUILDING_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getBuilding = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_BUILDING_REQUEST });            
      const { data } = await Axios.get(`/library/building/${id}`);      
      dispatch({ 
        type: dataConstants.GET_BUILDING_SUCCESS,
        payload: data?.data,
      });            
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_BUILDING_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageBuildingParts = (form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_BUILDING_PARTS_REQUEST });            
      const { data } = await Axios.post(`/library/building-parts/${module}`, form);      
      dispatch({ 
        type: dataConstants.MANAGE_BUILDING_PARTS_SUCCESS,
        payload: {
          message: data?.message,          
          data: data?.data          
        }
      });
      dispatch({
        type: appConstants.UPDATE_BUILDING,
        payload: data?.data
      })
    }catch(error){      
      dispatch({ 
        type: dataConstants.MANAGE_BUILDING_PARTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getTransactions = (page, limit, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_TRANSACTIONS_REQUEST });            
      let URL = `/library/transactions?page=${page}&limit=${limit}`;
      if(filter?.startDate && filter?.endDate) URL += `&startDate=${filter.startDate}&endDate=${filter.endDate}`;
      if(filter?.accession) URL += `&accession=${filter.accession}`;
      if(filter?.student) URL += `&student=${filter.student}`;
      if(filter?.status) URL += `&status=${filter.status}`;      
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_SUCCESS,
        payload: data?.data,
      });            
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getPrintData = (page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_PRINT_DATA_REQUEST });            
      const { data } = await Axios.get(`/library/print-barcodes?page=${page}&limit=${limit}`);
      dispatch({ 
        type: dataConstants.GET_PRINT_DATA_SUCCESS,
        payload: data?.data,
      });            
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_PRINT_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getReports = (filter) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_REPORTS_REQUEST });            
      let URL = `/library/reports`;
      if(filter?.startDate && filter?.endDate) URL += `?startDate=${filter.startDate}&endDate=${filter.endDate}`;
      if(filter?.circulationStatus) URL += `&circulationStatus=${filter.circulationStatus}`;
      if(filter?.paymentStatus) URL += `&paymentStatus=${filter.paymentStatus}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: dataConstants.GET_REPORTS_SUCCESS,
        payload: data?.data,
      });            
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_REPORTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}