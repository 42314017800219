import { combineReducers } from "redux";

import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import appReducer from "./app.reducer";
import dataReducer from "./data.reducer";
import circulationReducer from "./circulation.reducer";

const rootReducer = combineReducers({    
  app: appReducer, 
  circulation: circulationReducer,
  data: dataReducer,
  menu: menuReducer,    
  user: userReducer,
});

export default rootReducer;