import { apiConfig } from "config";
import { states } from "./constant-data";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]

export const formatDate = (date) => {
  let formattedDate;  
  if(typeof date === "object"){
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){
    formattedDate = `${monthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`
  }
  return formattedDate
}

export const covertHtmlInputDate = (date) => {
  let _date = new Date(date);
  return _date.toLocaleDateString("sv-SE");
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR = (cost) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);
}

export const getAssetURL = (filename) => {
  return "/assets/"+filename;
}

export const generatePublicUrl = (file) => {
  if(file?.includes("http") || file?.includes("https")) return file;
  if(file?.split("/").length > 1) return file;
  return apiConfig.MEDIA_URL + "/" + file
}

export const generateProtectedPublicUrl = (id) => {
  return apiConfig.PROTECTED_MEDIA_URL + "/" + id + "?type=library&file=student";
}

export const formatAddress = (address, city, state, country, zip) => {
  let _address = address ? `${address}` : "";
  let _city = city ? `, ${city}` : "";
  let _state = state ? `, ${states?.find((item) => item?.value === state)?.label} ` : "";
  let _country = country ? `${country}` : "";
  let _zip = zip ? `, ${zip}` : "";
  return _address + _city + _state + _country + _zip;
}

export const formatDateAndTime = (date) => {
  let _date = new Date(date);
  return _date.toLocaleString("en-IN");  
}

export const formatName = (firstName, middleName, lastName) => {  
  let _middleName = middleName ? ` ${middleName}` : "";
  let _lastName = lastName ? ` ${lastName}` : "";
  return firstName + _middleName + _lastName;
}

export const formatAccessionLocation = (shelf, rack, floor, building) => {
  return `${shelf || ""} - ${rack || ""} - ${floor || ""} - ${building || ""}`;
}

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);  
  return result;
}