import React, { useRef } from "react";
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FullScreenDialog = ({
  open,
  setOpen,
  title="Filter",
  saveText="Apply",
  onSave=() => {},
  color="primary",
  print=false,
  children
}) => {

  const printRef = useRef();
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    onSave();
    setOpen(false);
  }

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}        
      >
        <AppBar 
          sx={{ position: 'fixed' }}
          color={color}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {print && <ReactToPrint
              trigger={() => <IconButton
                color="inherit"                
                aria-label="print"
              >
                <PrintIcon />
              </IconButton>}
              content={() => printRef.current}
              pageStyle="@page { size: auto; margin: 3mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
              onBeforeGetContent={() => { document.title = `${title}.pdf`; }}
              onAfterPrint={() => { document.title = `${title}.pdf`; }}              
            />}            
            <Button 
              autoFocus 
              color="inherit" 
              onClick={handleSave}
            >
              {saveText}
            </Button>
          </Toolbar>
        </AppBar>
        <Box mt={{xs: 10, md: 8}} />
        <Box sx={{ overflow: 'auto' }} ref={printRef} className="print-container">
          {children}
        </Box>
      </Dialog>
    </>
  )
}

export default FullScreenDialog;