import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  building: null,
  transactionData: null, 
  printData: null,
  reportData: null 
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) {    
    case dataConstants.MANAGE_CATALOGUE_REQUEST:
    case dataConstants.MANAGE_ACQUISITION_REQUEST:
    case dataConstants.MANAGE_BUILDING_REQUEST:
    case dataConstants.GET_BUILDING_REQUEST:
    case dataConstants.MANAGE_BUILDING_PARTS_REQUEST:
    case dataConstants.GET_TRANSACTIONS_REQUEST:
    case dataConstants.GET_PRINT_DATA_REQUEST:
    case dataConstants.GET_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,        
      }

    case dataConstants.MANAGE_CATALOGUE_SUCCESS:
    case dataConstants.MANAGE_ACQUISITION_SUCCESS:
    case dataConstants.MANAGE_BUILDING_SUCCESS:
      return {
        ...state,
        message: action.payload.message,        
        loading: false,
      }    

    case dataConstants.GET_BUILDING_SUCCESS:
      return {
        ...state,
        building: action.payload,        
        loading: false,
      }

    case dataConstants.MANAGE_BUILDING_PARTS_SUCCESS:
      return {
        ...state,
        building: action.payload?.data,
        message: action.payload?.message,        
        loading: false,
      }

    case dataConstants.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionData: action.payload,        
        loading: false,
      }

    case dataConstants.GET_PRINT_DATA_SUCCESS:
      return {
        ...state,
        printData: action.payload,        
        loading: false,
      }

    case dataConstants.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reportData: action.payload,        
        loading: false,
      }

    case dataConstants.MANAGE_CATALOGUE_FAILURE:
    case dataConstants.MANAGE_ACQUISITION_FAILURE:
    case dataConstants.MANAGE_BUILDING_FAILURE:
    case dataConstants.GET_BUILDING_FAILURE:
    case dataConstants.MANAGE_BUILDING_PARTS_FAILURE:
    case dataConstants.GET_TRANSACTIONS_FAILURE:
    case dataConstants.GET_PRINT_DATA_FAILURE:
    case dataConstants.GET_REPORTS_FAILURE:
      return {
        ...state,
        error: action.payload,        
        loading: false,
      }
  
    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;