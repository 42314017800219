import { DashboardOutlined } from "@ant-design/icons";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import LocalConvenienceStoreOutlinedIcon from '@mui/icons-material/LocalConvenienceStoreOutlined';
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

const icons = {
  AccountTreeOutlinedIcon,
  DashboardOutlined,
  LocalConvenienceStoreOutlinedIcon,
  QrCode2OutlinedIcon,
  SellOutlinedIcon  
};

const pages = {
  id: "pages",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "catalogue",
      title: "Catalogue",
      type: "item",
      url: "/catalogue",
      icon: icons.AccountTreeOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    },
    {
      id: "acquisition",
      title: "Acquisition",
      type: "item",
      url: "/acquisition",
      icon: icons.SellOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    },
    {
      id: "building",
      title: "Building",
      type: "item",
      url: "/building",
      icon: icons.LocalConvenienceStoreOutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2]
    },
    {
      id: "print-barcodes",
      title: "Print Barcodes",
      type: "item",
      url: "/print-barcodes",
      icon: icons.QrCode2OutlinedIcon,
      breadcrumbs: true,
      roles: [1, 2, 3]
    }    
  ]
};

export default pages;