import {   
  appConstants,
  circulationConstants,
  dataConstants,    
  userConstants
} from "redux/constants";
import Axios from "utils/axios";

export const login = (email, password, redirect) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.LOGIN_REQUEST });
      const config = { 
        headers: {
          "Content-Type": "application/json"
        }, 
        withCredentials: true, 
        credentials: "include"
      };
      const { data } = await Axios.post(`/auth/library/login`, { email, password }, config);            
      dispatch({ type: userConstants.LOGIN_SUCCESS, payload: data.data });      
      dispatch(loadUser());
      dispatch({ type: appConstants.SET_REDIRECT, payload: redirect });
    }catch(error){
      dispatch({ 
        type: userConstants.LOGIN_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const loadUser = () => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.LOAD_USER_REQUEST });
      const { data } = await Axios.get(`/auth/library/me`);           
      dispatch({ type: userConstants.LOAD_USER_SUCCESS, payload: data?.data });
    }catch(error){
      dispatch({ 
        type: userConstants.LOAD_USER_FAILURE, 
        payload: error.response.data?.error 
      });
    }
  }
}

export const logout = () => {
  return async dispatch => {
    try{      
      await Axios.get(`/auth/logout`);      
      dispatch({ type: userConstants.LOGOUT_SUCCESS });
      //RESET ALL REDUCERS      
      dispatch({ type: appConstants.RESET });                               
      dispatch({ type: circulationConstants.RESET });
      dispatch({ type: dataConstants.RESET });
    }catch(error){
      dispatch({ 
        type: userConstants.LOGOUT_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const forgotPassword = (email) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.FORGOT_PASSWORD_REQUEST });
      const config = { 
        headers: {
          "Content-Type": "application/json"
        }        
      };
      const { data } = await Axios.post(`/auth/library/forgot-password`, { email }, config);      
      dispatch({ 
        type: userConstants.FORGOT_PASSWORD_SUCCESS, 
        payload: { 
          message: data.message,
          success: data.success
        } 
      });
    }catch(error){
      dispatch({ 
        type: userConstants.FORGOT_PASSWORD_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const resetPassword = ({email, token, password, confirmPassword}) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.RESET_PASSWORD_REQUEST });
      const config = { 
        headers: {
          "Content-Type": "application/json"
        }        
      };
      let URL = `/auth/library/reset-password?token=${token}`;
      const { data } = await Axios.post(URL, { password, confirmPassword, email }, config);      
      dispatch({ 
        type: userConstants.RESET_PASSWORD_SUCCESS, 
        payload: { 
          message: data.message,
          success: data.success
        } 
      });
    }catch(error){
      dispatch({ 
        type: userConstants.RESET_PASSWORD_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}