export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {  
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",    
  GET_ACCESSIONS_REQUEST: "GET_ACCESSIONS_REQUEST",
  GET_ACCESSIONS_SUCCESS: "GET_ACCESSIONS_SUCCESS",
  GET_ACCESSIONS_FAILURE: "GET_ACCESSIONS_FAILURE",
  GET_ACCESSION_REQUEST: "GET_ACCESSION_REQUEST",
  GET_ACCESSION_SUCCESS: "GET_ACCESSION_SUCCESS",
  GET_ACCESSION_FAILURE: "GET_ACCESSION_FAILURE",
  MANAGE_ACCESSION_REQUEST: "MANAGE_ACCESSION_REQUEST",
  MANAGE_ACCESSION_SUCCESS: "MANAGE_ACCESSION_SUCCESS",
  MANAGE_ACCESSION_FAILURE: "MANAGE_ACCESSION_FAILURE",
  UPDATE_CATALOGUE: "UPDATE_CATALOGUE",
  UPDATE_ACQUISITION: "UPDATE_ACQUISITION",
  UPDATE_BUILDING: "UPDATE_BUILDING",  
  SET_REDIRECT: "SET_REDIRECT",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  UPDATE_AVATAR_REQUEST: "UPDATE_AVATAR_REQUEST",
  UPDATE_AVATAR_SUCCESS: "UPDATE_AVATAR_SUCCESS",
  UPDATE_AVATAR_FAILURE: "UPDATE_AVATAR_FAILURE",  
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const dataConstants = {
  MANAGE_CATALOGUE_REQUEST: "MANAGE_CATALOGUE_REQUEST",
  MANAGE_CATALOGUE_SUCCESS: "MANAGE_CATALOGUE_SUCCESS",
  MANAGE_CATALOGUE_FAILURE: "MANAGE_CATALOGUE_FAILURE",
  MANAGE_ACQUISITION_REQUEST: "MANAGE_ACQUISITION_REQUEST",
  MANAGE_ACQUISITION_SUCCESS: "MANAGE_ACQUISITION_SUCCESS",
  MANAGE_ACQUISITION_FAILURE: "MANAGE_ACQUISITION_FAILURE",
  MANAGE_BUILDING_REQUEST: "MANAGE_BUILDING_REQUEST",
  MANAGE_BUILDING_SUCCESS: "MANAGE_BUILDING_SUCCESS",
  MANAGE_BUILDING_FAILURE: "MANAGE_BUILDING_FAILURE",
  GET_BUILDING_REQUEST: "GET_BUILDING_REQUEST",
  GET_BUILDING_SUCCESS: "GET_BUILDING_SUCCESS",
  GET_BUILDING_FAILURE: "GET_BUILDING_FAILURE",
  MANAGE_BUILDING_PARTS_REQUEST: "MANAGE_BUILDING_PARTS_REQUEST",
  MANAGE_BUILDING_PARTS_SUCCESS: "MANAGE_BUILDING_PARTS_SUCCESS",
  MANAGE_BUILDING_PARTS_FAILURE: "MANAGE_BUILDING_PARTS_FAILURE",
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",
  GET_PRINT_DATA_REQUEST: "GET_PRINT_DATA_REQUEST",
  GET_PRINT_DATA_SUCCESS: "GET_PRINT_DATA_SUCCESS",
  GET_PRINT_DATA_FAILURE: "GET_PRINT_DATA_FAILURE",
  GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const circulationConstants = {
  MANAGE_CIRCULATION_REQUEST: "MANAGE_CIRCULATION_REQUEST",
  MANAGE_STUDENT_CIRCULATION_ISSUE_SUCCESS: "MANAGE_STUDENT_CIRCULATION_ISSUE_SUCCESS",
  MANAGE_FACULTY_CIRCULATION_ISSUE_SUCCESS: "MANAGE_FACULTY_CIRCULATION_ISSUE_SUCCESS",
  MANAGE_CIRCULATION_RETURN_SUCCESS: "MANAGE_CIRCULATION_RETURN_SUCCESS",
  MANAGE_CIRCULATION_FAILURE: "MANAGE_CIRCULATION_FAILURE",  
  GET_CIRCULATIONS_REQUEST: "GET_CIRCULATIONS_REQUEST",
  GET_STUDENT_CIRCULATIONS_SUCCESS: "GET_STUDENT_CIRCULATIONS_SUCCESS",
  GET_FACULTY_CIRCULATIONS_SUCCESS: "GET_FACULTY_CIRCULATIONS_SUCCESS",
  GET_CIRCULATIONS_FAILURE: "GET_CIRCULATIONS_FAILURE",
  GET_CIRCULATION_REQUEST: "GET_CIRCULATION_REQUEST",
  GET_STUDENT_CIRCULATION_SUCCESS: "GET_STUDENT_CIRCULATION_SUCCESS",
  GET_FACULTY_CIRCULATION_SUCCESS: "GET_FACULTY_CIRCULATION_SUCCESS",
  GET_CIRCULATION_FAILURE: "GET_CIRCULATION_FAILURE",
  NOTIFY_CIRCULATION_REQUEST: "NOTIFY_CIRCULATION_REQUEST",
  NOTIFY_CIRCULATION_SUCCESS: "NOTIFY_CIRCULATION_SUCCESS",
  NOTIFY_CIRCULATION_FAILURE: "NOTIFY_CIRCULATION_FAILURE",
  PAY_FINE_REQUEST: "PAY_FINE_REQUEST",
  PAY_FINE_SUCCESS: "PAY_FINE_SUCCESS",
  PAY_FINE_FAILURE: "PAY_FINE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}