import { circulationConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  circulationData: null,
  circulation: null,
  facultyCirculationData: null,
  facultyCirculation: null
};

const circulationReducer = (state = initState, action) => {      
  switch (action.type) {    
    case circulationConstants.MANAGE_CIRCULATION_REQUEST:
    case circulationConstants.GET_CIRCULATIONS_REQUEST:            
    case circulationConstants.GET_CIRCULATION_REQUEST:
    case circulationConstants.NOTIFY_CIRCULATION_REQUEST:
    case circulationConstants.PAY_FINE_REQUEST:
      return {
        ...state,
        loading: true,
      };

      case circulationConstants.MANAGE_CIRCULATION_RETURN_SUCCESS:
      case circulationConstants.NOTIFY_CIRCULATION_SUCCESS:
      case circulationConstants.PAY_FINE_SUCCESS:
          return {
            ...state,        
            message: action.payload?.message,
            loading: false,
          };

      case circulationConstants.MANAGE_STUDENT_CIRCULATION_ISSUE_SUCCESS:      
        return {
          ...state,          
          circulationData: state.circulationData ? {
            ...state.circulationData,
            circulations: [action.payload?.data, ...state.circulationData?.circulations]
          } : null,
          message: action.payload?.message,
          loading: false,
        }

      case circulationConstants.MANAGE_FACULTY_CIRCULATION_ISSUE_SUCCESS:
        return {
          ...state,             
          facultyCirculationData: state.facultyCirculationData ? {
            ...state.facultyCirculationData,
            circulations: [action.payload?.data, ...state.facultyCirculationData?.circulations]
          } : null,
          message: action.payload?.message,
          loading: false,
        }        
          
    case circulationConstants.GET_STUDENT_CIRCULATIONS_SUCCESS:
      return {
        ...state,
        circulationData: action.payload,
        loading: false,
      };

    case circulationConstants.GET_FACULTY_CIRCULATIONS_SUCCESS:
      return {
        ...state,
        facultyCirculationData: action.payload,
        loading: false,
      }

    case circulationConstants.GET_STUDENT_CIRCULATION_SUCCESS:
      return {
        ...state,
        circulation: action.payload,
        loading: false,
      };

    case circulationConstants.GET_FACULTY_CIRCULATION_SUCCESS:
      return {
        ...state,
        facultyCirculation: action.payload,
        loading: false,
      };
        
    case circulationConstants.MANAGE_CIRCULATION_FAILURE:
    case circulationConstants.GET_CIRCULATIONS_FAILURE:            
    case circulationConstants.GET_CIRCULATION_FAILURE:
    case circulationConstants.NOTIFY_CIRCULATION_FAILURE:
    case circulationConstants.PAY_FINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case circulationConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case circulationConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default circulationReducer;