import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const Accession = Loadable(lazy(() => import("pages/accession")));
const AddAccession = Loadable(lazy(() => import("pages/accession/add")));
const EditAccession = Loadable(lazy(() => import("pages/accession/edit")));

const Circulation = Loadable(lazy(() => import("pages/circulation")));
const CirculationDetail = Loadable(lazy(() => import("pages/circulation/detail")));

const Catalogue = Loadable(lazy(() => import("pages/catalogue")));

const Acquisition = Loadable(lazy(() => import("pages/acquisition")));

const Building = Loadable(lazy(() => import("pages/building")));
const ManageBuilding = Loadable(lazy(() => import("pages/building/manage")));

const Transactions = Loadable(lazy(() => import("pages/transaction")));
const PrintBarcodes = Loadable(lazy(() => import("pages/print-barcode")));
const Reports = Loadable(lazy(() => import("pages/report")));
const Profile = Loadable(lazy(() => import("pages/profile")));

const FacultyCirculation = Loadable(lazy(() => import("pages/faculty-circulation")));
const FacultyCirculationDetail = Loadable(lazy(() => import("pages/faculty-circulation/detail")));

const PrivateRoute = ({ children }) => {  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated } = useSelector(state => state.user);  

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : <>
      {children}      
    </>); 
};

const AuthorizedRoute = ({ roles, children }) => {
  const { user } = useSelector(state => state.user);  
  return user &&
    (roles?.includes(user?.role) ? children : <Navigate to="/404" />);
}

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "catalogue",
      element: <AuthorizedRoute roles={[1, 2]}><Catalogue/></AuthorizedRoute>
    },
    {
      path: "acquisition",
      element: <AuthorizedRoute roles={[1, 2]}><Acquisition/></AuthorizedRoute>
    },
    {
      path: "building",
      children: [
        {
          path: "",
          element: <AuthorizedRoute roles={[1, 2]}><Building/></AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute roles={[1, 2]}><ManageBuilding/></AuthorizedRoute>
        }
      ]      
    },
    {
      path: "accession",
      children: [
        {
          path: "",
          element: <AuthorizedRoute roles={[1, 2, 3]}><Accession/></AuthorizedRoute>
        },
        {
          path: "add",
          element: <AuthorizedRoute roles={[1, 2, 3]}><AddAccession/></AuthorizedRoute>
        },
        {
          path: "edit/:id",
          element: <AuthorizedRoute roles={[1, 2, 3]}><EditAccession/></AuthorizedRoute>
        }
      ]      
    },
    {
      path: "circulation",
      children: [
        {
          path: "",
          element: <AuthorizedRoute roles={[1, 2, 3]}><Circulation/></AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute roles={[1, 2, 3]}><CirculationDetail/></AuthorizedRoute>
        }
      ]
    },
    {
      path: "transactions",
      element: <Transactions/>
    },
    {
      path: "print-barcodes",
      element: <PrintBarcodes/>
    },
    {
      path: "reports",
      element: <Reports/>
    },
    {
      path: "profile",
      element: <Profile/>
    },
    {
      path: "faculty-circulation",
      children: [
        {
          path: "",
          element: <AuthorizedRoute roles={[1, 2, 3]}><FacultyCirculation/></AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute roles={[1, 2, 3]}><FacultyCirculationDetail/></AuthorizedRoute>
        }
      ]
    },     
  ]
};

export default MainRoutes;